@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  box-shadow: none !important;
}

.btn.btn-primary {

  color: rgba(245, 245, 245, 0);
  background-color: rgba(245, 245, 245, 0) !important;
  border-color: rgba(245, 245, 245, 0) !important;

  &:hover {
    img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }
    img:active {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }
    color: rgba(245, 245, 245, 0);
    background-color: rgba(245, 245, 245, 0) !important;
    border-color: rgba(245, 245, 245, 0) !important;
  }
}
