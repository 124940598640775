@import "../../../scss/index";

.basic-modal{
  margin-top: 40px !important;
  border-color: $background-transparente;
  background-color: $background-transparente;
  .modal-content{
    //background-color: $background-transparente;
    border-radius: 25px;
    border-color: $background-transparente;
    overflow: hidden;

    .modal-header{
      padding: 20px 20px;
      border: 15px;

      .modal-title{
        padding: 15px;
        border: 0;
        display: block;
        margin: -10px 0 -10px auto;

        svg {
          width: 35px;
          height: 35px;
          fill: $primary;
          &:hover {
            cursor: pointer;
          }
        }

      }
    }
    .modal-body{
      padding: 20px 40px 40px 40px;
    }
  }
}
