@import "../../scss/index";

.btn.btn-primary {
  background-color: rgba(104,222,249,255) !important;
  border-color: rgba(104,222,249,255) !important;
  border-radius: 50px !important;

  &:hover {
    background-color: rgba(104,222,249,255) !important;
    border-color: rgba(104,222,249,255) !important;
  }
}

/* Estilos diseñados para la vista de telefono y tableta */

@media only screen and (max-width: 980px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100% !important;
    left: 0 !important;
  }
  .fondoPrincipal {
    width: 100%;
  }

  // Para el hover de las imagenes     filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);

  /* Contenedor de componentes en pantalla */
  .botonesPrincipales {
    margin-top: -202% !important;
    display: block;
    //display: flex;
    margin-left: 5px !important;

    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none !important;
    }

    .bienvenida {

      .bienvenidos {
        margin-top: 10px !important;
        margin-left: 15px !important;
        width: 70% !important;
      }
      .textoBienvenida {
        margin-top: 10px !important;
        width: 92% !important;
        margin-left: 15px !important;
      }

      .botonVervideo {
        color: white !important;
        margin-top: -45px !important;
        margin-left: 11px !important;
        font-size: 22px !important;

        .btn.btn-primary {
          border-color: white !important;
          background-color: rgba(104,222,249,255) !important;
          border-radius: 50px !important;

          &:hover {
            background-color: rgba(245, 245, 245, 0);
            border-color: rgba(245, 245, 245, 0);
          }
        }
      }

      .hidrobot {
        display: block !important;
        margin-top: -56px !important;
        margin-left: 158px !important;
        width: 190px;
        height: 300px;
      }
      .invisibleHR {
        background-color: rgba(255,255,255,0);
      }

      .iconos {
        width: 100% !important;
        margin-top: -80% !important;
        margin-left: 0px !important;
        .invisibleHR {
          background-color: rgba(255,255,255,0);
        }

        .aprende {
          width: 20% !important;
          margin-left: 16px !important;
          //689px
        }
        .conciencia {
          width: 20% !important;
          margin-left: 6px !important;
        }
        .diviertete {
          width: 20% !important;
          margin-left: 16px !important;
          margin-top: -25px !important;
        }
        .interactua {
          width: 20% !important;
          margin-left: 6px !important;
          margin-top: -25px !important;
        }
        .juega {
          width: 20% !important;
          margin-top: -25px !important;
          margin-left: 50px !important;
        }
      }

    }

    .swiper-container-3d {
      margin-top: 140px !important;
    }


    /* Inicia acomodo de botones */
    .botonRecorridoVirtual {
      z-index: 2;
      width: 75% !important;
      //margin-top: -400%;
    }

    .botonEtapa1 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -330%;
    }

    .botonEtapa2 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -260%;
    }

    .botonEtapa3 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -210%;
    }
    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */
    .textoRecorridoVirtual {
      z-index: 2;
      width: 60%;
      margin-left: 80px !important;
      display: block;
      //margin-top: -346%;
      //margin-left: 77px;
    }

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 120px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 120px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa3 {
      z-index: 2;
      width: 60%;
      margin-left: 80px !important;
      display: block;
      //margin-left: 20%;
      //margin-top: -170%;
    }
    /* Termina acomodo de textos */
  }

}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesPrincipales {
    margin-top: -202% !important;
    display: block;
    //display: flex;
    margin-left: 40px !important;

    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none !important;
    }

    .bienvenida {
      .bienvenidos {
        font-size: 50px !important;
        color: $font-light !important;
      }
      .textoBienvenida {
        font-size: 35px !important;
        text-align: justify-all !important;
        color: $font-light !important;
      }

      .botonVervideo {
        color: white !important;
        margin-top: -92px !important;
        margin-left: 3px !important;
        font-size: 36px !important;

        .btn.btn-primary {
          border-color: white !important;
          background-color: rgba(104,222,249,255) !important;

          &:hover {
            background-color: rgba(245, 245, 245, 0);
            border-color: rgba(245, 245, 245, 0);
          }
        }
      }

      .hidrobot {
        display: block !important;
        margin-left: 347px !important;
        width: 330px;
        height: 470px;
      }

      .invisibleHR {
        background-color: rgba(255,255,255,0);
      }

      .iconos {
        width: 100% !important;
        margin-top: -65% !important;
        margin-left: 0px !important;
        .invisibleHR {
          background-color: rgba(255,255,255,0);
        }

        .aprende {
          width: 20% !important;
          //689px
        }
        .conciencia {
          width: 20% !important;
          margin-left: 15px !important;
        }
        .diviertete {
          width: 20% !important;
          margin-top: -25px !important;
        }
        .interactua {
          width: 20% !important;
          margin-left: 15px !important;
          margin-top: -25px !important;
        }
        .juega {
          width: 20% !important;
          margin-top: -25px !important;
          margin-left: 75px !important;
        }
      }

    }

    .swiper-container-3d {
      margin-top: 330px !important;
    }


    /* Inicia acomodo de botones */
    .botonRecorridoVirtual {
      z-index: 2;
      width: 100% !important;
      //margin-top: -400%;
    }

    .botonEtapa1 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -330%;
    }

    .botonEtapa2 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -260%;
    }

    .botonEtapa3 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -210%;
    }
    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */
    .textoRecorridoVirtual {
      z-index: 2;
      width: 60%;
      margin-left: 109px !important;
      display: block;
      //margin-top: -346%;
      //margin-left: 77px;
    }

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 184px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 184px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa3 {
      z-index: 2;
      width: 60%;
      margin-left: 105px !important;
      display: block;
      //margin-left: 20%;
      //margin-top: -170%;
    }
    /* Termina acomodo de textos */
  }

}

// Vista personalizada
@media only screen and (min-width: 411px) and  (max-width: 731px) {
  .etiquetaMenu {
    position: fixed !important;
    width: 100% !important;
    left: 0 !important;
  }
  .fondoPrincipal {
    width: 100%;
  }

  // Para el hover de las imagenes     filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);

  /* Contenedor de componentes en pantalla */
  .botonesPrincipales {
    margin-top: -202% !important;
    display: block;
    //display: flex;
    margin-left: 5px !important;

    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none !important;
    }

    .bienvenida {

      .bienvenidos {
        margin-top: 10px !important;
        margin-left: 15px !important;
        width: 70% !important;
      }
      .textoBienvenida {
        margin-top: 10px !important;
        width: 92% !important;
        margin-left: 15px !important;
      }

      .botonVervideo {
        color: white !important;
        margin-top: -56px !important;
        margin-left: 10px !important;
        font-size: 22px !important;

        .btn.btn-primary {
          border-color: white !important;
          background-color: rgba(104,222,249,255) !important;
          border-radius: 50px !important;

          &:hover {
            background-color: rgba(245, 245, 245, 0);
            border-color: rgba(245, 245, 245, 0);
          }
        }
      }

      .hidrobot {
        display: block !important;
        margin-top: -56px !important;
        margin-left: 193px !important;
        width: 190px;
        height: 300px;
      }
      .invisibleHR {
        background-color: rgba(255,255,255,0);
      }

      .iconos {
        width: 100% !important;
        margin-top: -74% !important;
        margin-left: 0px !important;
        .invisibleHR {
          background-color: rgba(255,255,255,0);
        }

        .aprende {
          width: 20% !important;
          margin-left: 16px !important;
          //689px
        }
        .conciencia {
          width: 20% !important;
          margin-left: 6px !important;
        }
        .diviertete {
          width: 20% !important;
          margin-left: 16px !important;
          margin-top: -25px !important;
        }
        .interactua {
          width: 20% !important;
          margin-left: 6px !important;
          margin-top: -25px !important;
        }
        .juega {
          width: 20% !important;
          margin-top: -25px !important;
          margin-left: 50px !important;
        }
      }

    }

    .swiper-container-3d {
      margin-top: 140px !important;
    }


    /* Inicia acomodo de botones */
    .botonRecorridoVirtual {
      z-index: 2;
      width: 75% !important;
      //margin-top: -400%;
    }

    .botonEtapa1 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -330%;
    }

    .botonEtapa2 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -260%;
    }

    .botonEtapa3 {
      z-index: 2;
      width: 75% !important;
      //margin-top: -210%;
    }
    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */
    .textoRecorridoVirtual {
      z-index: 2;
      width: 60%;
      margin-left: 80px !important;
      display: block;
      //margin-top: -346%;
      //margin-left: 77px;
    }

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 120px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 120px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa3 {
      z-index: 2;
      width: 60%;
      margin-left: 80px !important;
      display: block;
      //margin-left: 20%;
      //margin-top: -170%;
    }
    /* Termina acomodo de textos */
  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesPrincipales {
    margin-top: -202% !important;
    display: block;
    //display: flex;
    margin-left: 80px !important;

    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none !important;
    }

    .bienvenida {
      .bienvenidos {
        margin-top: 15px !important;
        width: 80% !important;
      }
      .textoBienvenida {
        margin-top: 20px !important;
        width: 95% !important;
      }

      .botonVervideo {
        color: white !important;
        margin-top: -130px !important;
        margin-left: 0px !important;
        font-size: 49px !important;

        .btn.btn-primary {
          border-color: white !important;
          background-color: rgba(104,222,249,255) !important;
          border-radius: 50px !important;

          &:hover {
            background-color: rgba(245, 245, 245, 0);
            border-color: rgba(245, 245, 245, 0);
          }
        }
      }

      .hidrobot {
        display: block !important;
        margin-left: 477px !important;
        width: 410px;
        height: 590px;
      }

      .invisibleHR {
        background-color: rgba(255,255,255,0);
      }

      .iconos {
        width: 100% !important;
        margin-top: -70% !important;
        margin-left: 0px !important;
        .invisibleHR {
          background-color: rgba(255,255,255,0);
        }

        .aprende {
          width: 20% !important;
          //689px
        }
        .conciencia {
          width: 20% !important;
          margin-left: 15px !important;
        }
        .diviertete {
          width: 20% !important;
          margin-top: -25px !important;
        }
        .interactua {
          width: 20% !important;
          margin-left: 15px !important;
          margin-top: -25px !important;
        }
        .juega {
          width: 20% !important;
          margin-top: -25px !important;
          margin-left: 75px !important;
        }
      }

    }

    .swiper-container-3d {
      margin-top: 450px !important;
    }


      /* Inicia acomodo de botones */
    .botonRecorridoVirtual {
      z-index: 2;
      margin-top: 20px !important;
      width: 100% !important;
      //margin-top: -400%;
    }

    .botonEtapa1 {
      z-index: 2;
      width: 116% !important;
      //margin-top: -330%;
    }

    .botonEtapa2 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -260%;
    }

    .botonEtapa3 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -210%;
    }
    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */
    .textoRecorridoVirtual {
      z-index: 2;
      width: 60%;
      margin-left: 33px !important;
      display: block;
      //margin-top: -346%;
      //margin-left: 77px;
    }

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 178px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 133px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa3 {
      z-index: 2;
      width: 60%;
      margin-left: 30px !important;
      display: block;
      //margin-left: 20%;
      //margin-top: -170%;
    }
    /* Termina acomodo de textos */
  }

}

@media only screen and (min-width: 1080px) and  (max-width: 1920px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    height: 20%;
    top: -1px;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesPrincipales {
    margin-top: -202% !important;
    display: block;
    //display: flex;
    margin-left: 320px !important;

    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none !important;
    }

    .bienvenida {
      .bienvenidos {
        color: $font-light !important;
      }
      .textoBienvenida {
        text-align: justify-all !important;
        color: $font-light !important;
      }
    }


    /* Inicia acomodo de botones */
    .botonRecorridoVirtual {
      z-index: 2;
      width: 100% !important;
      //margin-top: -400%;
    }

    .botonEtapa1 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -330%;
    }

    .botonEtapa2 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -260%;
    }

    .botonEtapa3 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -210%;
    }
    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */
    .textoRecorridoVirtual {
      z-index: 2;
      width: 60%;
      margin-left: 90px !important;
      display: block;
      //margin-top: -346%;
      //margin-left: 77px;
    }

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa3 {
      z-index: 2;
      width: 60%;
      margin-left: 80px !important;
      display: block;
      //margin-left: 20%;
      //margin-top: -170%;
    }
    /* Termina acomodo de textos */
  }

}

.etiquetaMenu {
  position: fixed !important;
  width: 100% !important;
  left: 0 !important;
}
.fondoPrincipal {
  width: 100%;
}

/* Contenedor de componentes en pantalla */
.botonesPrincipales {
  margin-top: -202%;
  display: block;
  //display: flex;

  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: none !important;
  }

  .bienvenida {
    .bienvenidos {
      color: $font-light !important;
    }
    .textoBienvenida {
      text-align: justify-all !important;
      color: $font-light !important;
    }
  }

  /* Inicia acomodo de botones */
  .botonRecorridoVirtual {
    z-index: 2;
    width: 60%;
    //margin-top: -400%;
  }

  .botonEtapa1 {
    z-index: 2;
    width: 60%;
    //margin-top: -330%;
  }

  .botonEtapa2 {
    z-index: 2;
    width: 60%;
    //margin-top: -260%;
  }

  .botonEtapa3 {
    z-index: 2;
    width: 60%;
    //margin-top: -210%;
  }
  /* Termina acomodo de botones */

  /* Inicia acomodo de textos */
  .textoRecorridoVirtual {
    z-index: 2;
    width: 60%;
    margin-left: 40px;
    display: block;
    //margin-top: -346%;
    //margin-left: 77px;
  }

  .textoEtapa1 {
    z-index: 2;
    width: 35%;
    margin-left: 50px;
    display: block;
    //margin-top: -245%;
    //margin-left: 30%;
  }

  .textoEtapa2 {
    z-index: 2;
    width: 35%;
    margin-left: 120px;
    display: block;
    //margin-top: -245%;
    //margin-left: 30%;
  }

  .textoEtapa3 {
    z-index: 2;
    width: 60%;
    margin-left: 30px !important;
    display: block;
    //margin-left: 20%;
    //margin-top: -170%;
  }
  /* Termina acomodo de textos */
}
