@import "../../scss/colors";

html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;
  color: $font-light !important;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */



/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $font-light !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  height: 106% !important;
}

/* General sidebar styles */
.bm-menu {
  background: #0071BC;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  //background-image: url("../../assets/MENUSOLO.png");
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #0071BC;
}

/* Wrapper for item list */
.bm-item-list {
  //color: #b8b7ad;
  margin-left: -14px !important;
  margin-top: -55px !important;

  /* Inicia ajuste de imagenes del menu */
  .imagenInicio {
    margin-left: -20px;
    width: 140% !important;
  }
  .imagenQueEsMICA {
    margin-left: -20px;
    width: 140% !important;
  }
  .imagenEtapa1 {
    margin-left: -20px;
    width: 140% !important;
  }
  .imagenEtapa2 {
    margin-left: -20px;
    width: 140% !important;
  }
  .imagenNuevosModulos {
    margin-left: -20px;
    width: 130% !important;
  }
  .imagenContacto {
    margin-left: -20px;
    width: 140% !important;
  }
  /* Termina ajuste de imagenes del menu */

  .bm-item {
    h2 a {
      //margin-left: 15px;
      text-decoration: none;
    }
    h2:hover {
      padding: 4px;
      border: 1px #fff solid;
    }

    .menu-item {
      text-align: center !important;
      //margin-left: 25px;
      h2:hover {
        padding: 2px;
        box-shadow: 0 1px 1px #0071BC inset, 0 0 4px #0071BC;
        //border: 1px $border-dark solid;
      }
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: #0071BC;
}

.dropdown .dropdown-menu
{
  color: #fff;
  background-color: #0071BC;
  border-color: #fff;
}

.dropdown .dropdown-toggle::after {
  display:none;
}

/* Para los link de navegacion*/
.homePrincipal {
  a {
    color: $font-light !important;
  }
}
.nav-link {
  color: $font-light !important;
}

.sesion {
  margin-top: 10px !important;
  margin-left: -15px !important;
}

.desplegable {
  color: $font-light !important;
  font-size: 25px !important;
  text-align: center !important;
}
