
.ocultaLoading {
  display: none !important;
}

.imagenSplashLoading {
  width: 100% !important;
}

@media only screen and (min-width: 360px) and  (max-width: 980px) {
  .barraLoading {
    z-index: 2;
    margin-top: -104% !important;
    margin-left: 32px !important;
    width: 311px !important;
  }
}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {
  .barraLoading {
    z-index: 2;
    margin-top: -104% !important;
    margin-left: 65px !important;
    width: 626px !important;
    height: 31px !important;
  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .barraLoading {
    z-index: 2;
    margin-top: -104% !important;
    margin-left: 89px !important;
    width: 842px !important;
    height: 41px !important;
  }
}
