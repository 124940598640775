

.etiquetaMenu {
  position: fixed !important;
  width: 100%;
  top: -1px;
  left: 0;
}
.fondoPrincipal {
  width: 100%;
}

/* Estilos diseñados para la vista de telefono y tableta */
@media only screen and (max-width: 980px) {
  .botonesEtapa1 {
    margin-top: -266% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa1 {
      z-index: 2;
      width: 45% !important;
      margin-left: -7px !important;
      margin-top: -97px !important;
    }

    .etapa2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 82px !important;
      margin-left: -5px !important;
    }

    .etapa3 {
      z-index: 2;
      width: 45% !important;
      margin-top: -136px !important;
      margin-left: -8px !important;
    }

    .etapa4 {
      z-index: 2;
      width: 46% !important;
      margin-top: 40px !important;
      margin-left: 1px !important;
    }

    .etapa5 {
      z-index: 2;
      width: 46% !important;
      margin-top: -130px !important;
      margin-left: -8px !important;
    }

    .etapa6 {
      z-index: 2;
      width: 45% !important;
      margin-top: 42px !important;
      margin-left: 0px !important;
    }

    .etapa7 {
      z-index: 2;
      width: 46% !important;
      margin-top: -130px !important;
      margin-left: -8px !important;
    }

    .etapa8 {
      z-index: 2;
      width: 45% !important;
      margin-top: 42px !important;
      margin-left: 0px !important;
    }

    .etapa9 {
      z-index: 2;
      width: 46% !important;
      margin-top: -40px !important;
      margin-left: -8px !important;
    }

    .inicio {
      margin-top: 0px !important;
      width: 60px !important;
      height: 60px !important;
      margin-left: 40px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -58px !important;
      width: 60px !important;
      height: 60px !important;
      margin-left: 205px !important;
      display: block !important;
    }
  }
}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {
  .botonesEtapa1 {
    margin-top: -266% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -20px !important;
      margin-top: -202px !important;
    }

    .etapa2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 163px !important;
      margin-left: -10px !important;
    }

    .etapa3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -290px !important;
      margin-left: -18px !important;
    }

    .etapa4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 86px !important;
      margin-left: -7px !important;
    }

    .etapa5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -297px !important;
      margin-left: -18px !important;
    }

    .etapa6 {
      z-index: 2;
      width: 47% !important;
      margin-top: 80px !important;
      margin-left: -13px !important;
    }

    .etapa7 {
      z-index: 2;
      width: 47% !important;
      margin-top: -291px !important;
      margin-left: -18px !important;
    }

    .etapa8 {
      z-index: 2;
      width: 47% !important;
      margin-top: 76px !important;
      margin-left: -8px !important;
    }

    .etapa9 {
      z-index: 2;
      width: 47% !important;
      margin-top: -105px !important;
      margin-left: -19px !important;
    }

    .inicio {
      margin-top: 25px !important;
      width: 80px !important;
      height: 80px !important;
      margin-left: 73px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -81px !important;
      width: 80px !important;
      height: 80px !important;
      margin-left: 418px !important;
      display: block !important;
    }
  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .botonesEtapa1 {
    margin-top: -266% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -20px !important;
      margin-top: -265px !important;
    }

    .etapa2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 217px !important;
      margin-left: -21px !important;
    }

    .etapa3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -362px !important;
      margin-left: -21px !important;
    }

    .etapa4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 111px !important;
      margin-left: -24px !important;
    }

    .etapa5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -378px !important;
      margin-left: -27px !important;
    }

    .etapa6 {
      z-index: 2;
      width: 47% !important;
      margin-top: 103px !important;
      margin-left: -13px !important;
    }

    .etapa7 {
      z-index: 2;
      width: 47% !important;
      margin-top: -376px !important;
      margin-left: -25px !important;
    }

    .etapa8 {
      z-index: 2;
      width: 47% !important;
      margin-top: 111px !important;
      margin-left: -20px !important;
    }

    .etapa9 {
      z-index: 2;
      width: 47% !important;
      margin-top: -138px !important;
      margin-left: -19px !important;
    }

    .inicio {
      margin-top: 25px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 73px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -110px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 600px !important;
      display: block !important;
    }

  }
}


