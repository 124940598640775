

.etiquetaMenu {
  position: fixed !important;
  width: 100%;
  top: -1px;
  left: 0;
}
.fondoPrincipal {
  width: 100%;
}

/* Estilos diseñados para la vista de telefono y tableta */
@media only screen and (max-width: 980px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100% !important;
    left: 0 !important;
  }
  .fondoPrincipal {
    width: 100%;
  }

  // Para el hover de las imagenes     filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);

  /* Contenedor de componentes en pantalla */
  .botonesRecorrido {
    margin-top: -180% !important;
    display: block;
    //display: flex;
    margin-left: 5px !important;

    .recorridoEtapa1 {
      z-index: 2;
      width: 80% !important;
      //margin-top: -330%;
    }

    .recorridoEtapa2 {
      z-index: 2;
      width: 80% !important;
      //margin-top: -260%;
    }

    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */

    .textoEtapa1 {
      z-index: 2;
      width: 45%;
      margin-left: 100px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 45%;
      margin-left: 100px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    /* Termina acomodo de textos */
    .inicio {
      margin-top: 88px !important;
      width: 75px !important;
      height: 75px !important;
      margin-left: 60px !important;
      display: block !important;
    }

  }

}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesRecorrido {
    margin-top: -180% !important;
    display: block;
    //display: flex;
    margin-left: 80px !important;


    /* Inicia acomodo de botones */

    .recorridoEtapa1 {
      z-index: 2;
      width: 85% !important;
      //margin-top: -330%;
    }

    .recorridoEtapa2 {
      z-index: 2;
      width: 85% !important;
      //margin-top: -260%;
    }

    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */

    .textoEtapa1 {
      z-index: 2;
      width: 45%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 45%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    /* Termina acomodo de textos */
    .inicio {
      margin-top: 360px !important;
      width: 80px !important;
      height: 80px !important;
      margin-left: 90px !important;
      display: block !important;
    }
  }

}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesRecorrido {
    margin-top: -180% !important;
    display: block;
    //display: flex;
    margin-left: 150px !important;


    /* Inicia acomodo de botones */

    .recorridoEtapa1 {
      z-index: 2;
      width: 120% !important;
      //margin-top: -330%;
    }

    .recorridoEtapa2 {
      z-index: 2;
      width: 120% !important;
      //margin-top: -260%;
    }

    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */

    .textoEtapa1 {
      z-index: 2;
      width: 45%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 45%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    /* Termina acomodo de textos */
    .inicio {
      margin-top: 470px !important;
      width: 85px !important;
      height: 85px !important;
      margin-left: 100px !important;
      display: block !important;
    }
  }

}

@media only screen and (min-width: 1080px) and  (max-width: 1920px) {

  .etiquetaMenu {
    position: fixed !important;
    width: 100%;
    height: 20%;
    top: -1px;
    left: 0;
  }
  .fondoPrincipal {
    width: 100%;
  }

  /* Contenedor de componentes en pantalla */
  .botonesRecorrido {
    margin-top: -180% !important;
    display: block;
    //display: flex;
    margin-left: 320px !important;


    /* Inicia acomodo de botones */

    .recorridoEtapa1 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -330%;
    }

    .recorridoEtapa2 {
      z-index: 2;
      width: 100% !important;
      //margin-top: -260%;
    }

    /* Termina acomodo de botones */

    /* Inicia acomodo de textos */

    .textoEtapa1 {
      z-index: 2;
      width: 35%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    .textoEtapa2 {
      z-index: 2;
      width: 35%;
      margin-left: 160px !important;
      display: block;
      //margin-top: -245%;
      //margin-left: 30%;
    }

    /* Termina acomodo de textos */
  }

}
