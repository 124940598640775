

.etiquetaMenu {
  position: fixed !important;
  width: 100%;
  top: -1px;
  left: 0;
}
.fondoPrincipal {
  width: 100%;
}

/* Estilos diseñados para la vista de telefono y tableta */
@media only screen and (max-width: 980px) {
  .botonesEtapa3 {
    margin-top: -150% !important;
    margin-left: 20% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa3M1 {
      z-index: 2;
      width: 83% !important;
      margin-left: -16px !important;
      margin-top: -123px !important;
    }

    .etapa3M2 {
      z-index: 2;
      width: 83% !important;
      margin-top: 26px !important;
      margin-left: -16px !important;
    }

    .inicio {
      margin-top: 63px !important;
      width: 60px !important;
      height: 60px !important;
      margin-left: 6px !important;
      display: block !important;
    }

  }
}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {
  .botonesEtapa3 {
    margin-top: -150% !important;
    margin-left: 20% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa3M1 {
      z-index: 2;
      width: 83% !important;
      margin-left: -32px !important;
      margin-top: -255px !important;
    }

    .etapa3M2 {
      z-index: 2;
      width: 83% !important;
      margin-top: 56px !important;
      margin-left: -30px !important;
    }

  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .botonesEtapa3 {
    margin-top: -150% !important;
    margin-left: 20% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa3M1 {
      z-index: 2;
      width: 83% !important;
      margin-left: -40px !important;
      margin-top: -336px !important;
    }

    .etapa3M2 {
      z-index: 2;
      width: 83% !important;
      margin-top: 71px !important;
      margin-left: -44px !important;
    }

  }
}
