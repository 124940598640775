@import "~bootstrap/scss/bootstrap";
@import "scss/colors";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ocultaLoading {
  display: none !important;
}

.contenedorPrincipal {
  margin-top: 15px !important;
  /* Estilos para el menu */

  #App {
    font-family: sans-serif;
    height: 100vh;
  }

  #page-wrap {
    text-align: center;
    color: $font-light !important;
    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }

  /* Position and sizing of burger button */
  @media only screen and (max-width: 812px) {
    .bm-burger-button {
      position: fixed !important;
      width: 32px !important;
      height: 30px !important;
      left: 11px !important;
      top: 8px !important;
    }
  }

  @media only screen and (min-width: 768px) and  (max-width: 1024px) {
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed !important;
      width: 65px !important;
      height: 60px !important;
      left: 24px !important;
      top: 18px !important;
    }
  }

  @media only screen and (min-width: 1024px) and  (max-width: 1366px) {
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed !important;
      width: 65px !important;
      height: 60px !important;
      left: 33px !important;
      top: 34px !important;
    }
  }

  .contenedorPrimerSlider {
    width: 100% !important;
  }

  .ocultaContenedorPrimerSlider {
    display: none !important;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $font-light !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 40px !important;
    width: 40px !important;
    margin-top: 0px !important;
    margin-right: -4px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $font-light !important;
  }

  .bm-menu-wrap {
    margin-top: -13px !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #0071BC;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    //background-image: url("../../assets/MENUSOLO.png");
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #0071BC;
  }

  /* Wrapper for item list */
  .bm-item-list {
    //color: #b8b7ad;
    margin-left: -14px !important;
    margin-top: -50px !important;

    /* Inicia ajuste de imagenes del menu */
    .imagenInicio {
      margin-left: -20px;
      width: 125% !important;
    }
    .imagenQueEsMICA {
      margin-left: -20px;
      width: 100% !important;
    }
    .imagenEtapa1 {
      margin-left: -20px;
      width: 100% !important;
    }
    .imagenEtapa2 {
      margin-left: -20px;
      width: 100% !important;
    }
    .imagenNuevosModulos {
      margin-left: -20px;
      width: 95% !important;
    }
    .imagenContacto {
      margin-left: -20px;
      width: 100% !important;
    }
    /* Termina ajuste de imagenes del menu */

    .bm-item {
      margin-top: 19px !important;
      color: $font-light !important;
      h2 a {
        //margin-left: 15px;
        text-decoration: none;
      }
      h1:hover {
        padding: 4px;
        border: 1px #fff solid;
      }
      h2:hover {
        padding: 4px;
        border: 1px #fff solid;
      }

      .menu-item {
        text-align: center !important;
        //margin-left: 25px;
        h2:hover {
          padding: 2px;
          box-shadow: 0 1px 1px #0071BC inset, 0 0 4px #0071BC;
          //border: 1px $border-dark solid;
        }
      }
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    margin-top: -12px !important;
    background: #0071BC;
  }

  .dropdown .dropdown-menu
  {
    color: #fff;
    background-color: #0071BC;
    border-color: #fff;
  }

  .dropdown .dropdown-toggle::after {
    display:none;
  }

  /* Para los link de navegacion*/
  .homePrincipal {
    a {
      color: $font-light !important;
    }
  }
  .nav-link {
    color: $font-light !important;
  }

  .sesion {
    margin-top: 10px !important;
    margin-left: -15px !important;
  }

  .desplegable {
    color: $font-light !important;
    font-size: 25px !important;
    text-align: center !important;
  }
}
