@import url('https://unpkg.com/leaflet@1.5.1/dist/leaflet.css');


.etiquetaMenu {
  position: fixed !important;
  width: 100%;
  top: -1px;
  left: 0;
}
.fondoPrincipal {
  width: 100%;
}

/* Estilos para adaptar el mapa a diferentes resoluciones */
// Telefonos
@media only screen and (min-width: 320px) and  (max-width: 568px) {
  .mapa {
    z-index: 2;
    margin-top: -59% !important;
    margin-left: 15px !important;
    display: block !important;

    .leaflet-container {
      width: 280px !important;
      height: 150px !important;
    }
  }
}

@media only screen and (min-width: 360px) and  (max-width: 640px) {
  .mapa {
    z-index: 2;
    margin-top: -59% !important;
    margin-left: 15px !important;
    display: block !important;

    .leaflet-container {
      width: 330px !important;
      height: 170px !important;
    }
  }
}

@media only screen and (min-width: 375px) and  (max-width: 812px) {
  .mapa {
    z-index: 2;
    margin-top: -58% !important;
    margin-left: 20px !important;
    display: block !important;

    .leaflet-container {
      width: 345px !important;
      height: 170px !important;
    }
  }
}

// Personalizado
@media only screen and (min-width: 411px) and  (max-width: 731px) {
  .mapa {
    z-index: 2;
    margin-top: -58% !important;
    margin-left: 30px !important;
    display: block !important;

    .leaflet-container {
      width: 352px !important;
      height: 186px !important;
    }
  }
}

// Termina dispositivos

// Tablets
@media only screen and (min-width: 768px) and  (max-width: 1024px) {
  .mapa {
    z-index: 2;
    margin-top: -58% !important;
    margin-left: 50px !important;
    display: block !important;

    .leaflet-container {
      width: 665px !important;
      height: 350px !important;
    }
  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .mapa {
    z-index: 2;
    margin-top: -58% !important;
    margin-left: 70px !important;
    display: block !important;

    .leaflet-container {
      width: 885px !important;
      height: 465px !important;
    }
  }
}
