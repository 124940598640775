

.etiquetaMenu {
  position: fixed !important;
  width: 100%;
  top: -1px;
  left: 0;
}
.fondoPrincipal {
  width: 100%;
}

/* Estilos diseñados para la vista de telefono y tableta */
@media only screen and (max-width: 980px) {
  .botonesEtapa2 {
    margin-top: -192% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa2M1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -7px !important;
      margin-top: -127px !important;
    }

    .etapa2M2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 109px !important;
      margin-left: -5px !important;
    }

    .etapa2M3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -147px !important;
      margin-left: -8px !important;
    }

    .etapa2M4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 69px !important;
      margin-left: 1px !important;
    }

    .etapa2M5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -20px !important;
      margin-left: -8px !important;
    }

    .inicio {
      margin-top: 23px !important;
      width: 60px !important;
      height: 60px !important;
      margin-left: 40px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -58px !important;
      width: 60px !important;
      height: 60px !important;
      margin-left: 205px !important;
      display: block !important;
    }


  }
}

@media only screen and (min-width: 768px) and  (max-width: 1024px) {
  .botonesEtapa2 {
    margin-top: -192% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa2M1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -18px !important;
      margin-top: -260px !important;
    }

    .etapa2M2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 218px !important;
      margin-left: -10px !important;
    }

    .etapa2M3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -288px !important;
      margin-left: -15px !important;
    }

    .etapa2M4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 144px !important;
      margin-left: -13px !important;
    }

    .etapa2M5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -48px !important;
      margin-left: -13px !important;
    }

    .inicio {
      margin-top: 25px !important;
      width: 80px !important;
      height: 80px !important;
      margin-left: 73px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -81px !important;
      width: 80px !important;
      height: 80px !important;
      margin-left: 418px !important;
      display: block !important;
    }
  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .botonesEtapa2 {
    margin-top: -192% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa2M1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -18px !important;
      margin-top: -337px !important;
    }

    .etapa2M2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 291px !important;
      margin-left: -20px !important;
    }

    .etapa2M3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -386px !important;
      margin-left: -15px !important;
    }

    .etapa2M4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 193px !important;
      margin-left: -13px !important;
    }

    .etapa2M5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -56px !important;
      margin-left: -13px !important;
    }

    .inicio {
      margin-top: 25px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 73px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -110px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 600px !important;
      display: block !important;
    }


  }
}

@media only screen and (min-width: 1024px) and  (max-width: 1366px) {
  .botonesEtapa2 {
    margin-top: -192% !important;
    margin-left: 13% !important;
    display: block !important;

    img:active, img:hover {
      filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    }

    .etapa2M1 {
      z-index: 2;
      width: 47% !important;
      margin-left: -18px !important;
      margin-top: -337px !important;
    }

    .etapa2M2 {
      z-index: 2;
      width: 47% !important;
      margin-top: 291px !important;
      margin-left: -20px !important;
    }

    .etapa2M3 {
      z-index: 2;
      width: 47% !important;
      margin-top: -386px !important;
      margin-left: -15px !important;
    }

    .etapa2M4 {
      z-index: 2;
      width: 47% !important;
      margin-top: 193px !important;
      margin-left: -13px !important;
    }

    .etapa2M5 {
      z-index: 2;
      width: 47% !important;
      margin-top: -56px !important;
      margin-left: -13px !important;
    }

    .inicio {
      margin-top: 25px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 73px !important;
      display: block !important;
    }

    .continuar {
      margin-top: -110px !important;
      width: 110px !important;
      height: 110px !important;
      margin-left: 600px !important;
      display: block !important;
    }


  }
}

